import * as React from "react"
import Seo from "../components/common/seo.js"
import Layout from "../components/common/layout.js"
import Hero from "../components/events/awards-hero-2024.js"

import judgesTitle from "../images/awards/judges.png"

import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import awardsTitle from "../images/awards/awards-title.png"

import ubMedia from "../images/awards/sponsors/ub-media.png"
import astral from "../images/awards/sponsors/astral.png"
import billups from "../images/awards/sponsors/billups.svg"
import broadsign from "../images/awards/sponsors/broadsign.svg"
import captivate from "../images/awards/sponsors/captivate.png"
import cineplex from "../images/awards/sponsors/cineplex-media.svg"
import lamar from "../images/awards/sponsors/Lamar.png"
import pattison from "../images/awards/sponsors/pattison.png"
import quebecor from "../images/awards/sponsors/quebecor.png"
import recMedia from "../images/awards/sponsors/rec-media.png"
import statements from "../images/awards/sponsors/statements.png"
import thinkOutside from "../images/awards/sponsors/think-outside.png"
import vendoMedia from "../images/awards/sponsors/vendo-media.png"
import mediaStaffing from "../images/awards/sponsors/media-staffing.png"
import impression from "../images/awards/sponsors/1mpression.png"
import lineSmall from "../images/awards/line-small.png"
import lineDecor from "../images/awards/line-decor.png"
import manifold from "../images/awards/sponsors/manifold-logo.png"
import perion from "../images/awards/sponsors/perion-2.png"
import tsg from "../images/awards/sponsors/tsg.png"
import vistar from "../images/awards/sponsors/vistar.png"
import bC from "../images/awards/sponsors/bc-logo.png"
import adapt from "../images/awards/sponsors/adapt.png"
import adease from "../images/awards/sponsors/adease.svg"
import environics from "../images/awards/sponsors/environics-analytics-logo.svg"
import g3 from "../images/awards/sponsors/g3.png"

import ajayD from "../images/awards/judges-2025/ajay-d.jpg"
import andreaP from "../images/awards/judges-2025/andrea-p.png"
import christinaR from "../images/awards/judges-2025/christina-r.png"
import kylaF from "../images/awards/judges-2025/kyla-f.jpg"
import markF from "../images/awards/judges-2025/mark-f.jpg"
import micheleB from "../images/awards/judges-2025/michele-b.png"
import nikkiS from "../images/awards/judges-2025/nikki-s.png"
import ravenO from "../images/awards/judges-2025/raven-o.jpg"
import shannonL from "../images/awards/judges-2025/shannon-l.jpg"
import janetXi from "../images/awards/judges-2025/janet-xi.jpg"




const Awards = () => (
  <div className="eventsPage membersZone awards">
    <Helmet>
    </Helmet>
    <Layout>
      <Seo title=" Awards | COMMB" />
      <div className="awards contactSecWrapper">
        <Hero />
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="mademoiselleSec">
          <h4 className="new-title">
            SAVE THE DATE
          </h4>
          <h5 className="new-sub-title">
            MAY 15TH
          </h5>
          <h4 className="new-title lg-font">
            ARCADIAN COURT
          </h4>
          <p className="address-font">
            401 Bay Street, Simpson, Tower 8th floor, Toronto, ON M5H 2Y4
          </p>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="timelineInfo sec-1">
          <div className="container" style={{overflow: 'visible'}}>
            <h2 className="sponsor-title">
              2025 SPONSORS
            </h2>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="sec-2">
          <div className="container title-sponsor" style={{ overflow: 'visible' }}>
            <a href="https://www.ubmedia.ca/" target="_blank">
              <img src={ubMedia} className="title-sponsor-img" />
            </a>
            <h3 className="sponsor-title title-sponsor-title">
              TITLE SPONSOR
            </h3>
            <img src={lineDecor} width="420" />
            <div className="awardsLogoContainer">
              <div>
                <a href="https://adaptmedia.com/" target="_blank">
                  <img src={adapt} className="astral" />
                </a>
              </div>
              <div>
                <a href="https://adeaseai.com/" target="_blank">
                  <img src={adease} className="astral" />
                </a>
              </div>
              <div>
                <a href="https://www.bellmedia.ca/advertising-sales/out-of-home/" target="_blank">
                  <img src={astral} className="astral" />
                </a>
              </div>
              <div>
                <a href="https://www.billups.com/" target="_blank">
                    <img src={billups} className="billups" />
                </a>
              </div>
              <div>
                <a href="https://www.brandedcities.ca/" target="_blank">
                  <img src={bC} className="bC" />
                </a>
              </div>
              <div>
                <a href="https://broadsign.com/broadsign-platform/" target="_blank">
                  <img src={broadsign} className="broadsign" />
                </a>
              </div>
              <div>
                <a href="https://www.captivate.com/" target="_blank">
                  <img src={captivate} className="captivate" />
                </a>
              </div>
              <div>
                <a href="https://media.cineplex.com/" target="_blank">
                  <img src={cineplex} className="cineplex" />
                </a>
              </div>
              <div>
                <a href="https://environicsanalytics.com/" target="_blank">
                  <img src={environics} className="environics" />
                </a>
              </div>
              <div>
                <a target="_blank">
                  <img src={g3} className="g3" />
                </a>
              </div>
              <div>
                <a href="https://lamar.com/" target="_blank">
                    <img src={lamar} className="lamar" />
                </a>
              </div>
              <div>
                <a href="https://manifolddatamining.com/" target="_blank">
                    <img src={manifold} className="manifold" />
                </a>
              </div>
              <div>
                <a href="https://www.pattisonoutdoor.com" target="_blank">
                  <img src={pattison} className="pattison" />
                </a>
              </div>
              <div>
                <a href="https://perion.com/" target="_blank">
                    <img src={perion} className="perion" />
                </a>
              </div>
              <div>
                <a href="https://www.quebecor.com/en/our-activities/out-of-home" target="_blank">
                  <img src={quebecor} className="quebecor" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://recmedia.com/" target="_blank">
                    <img src={recMedia} className="recMedia" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://statementsmedia.com/" target="_blank">
                    <img src={statements} className="statements" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://talonooh.com/can/" target="_blank">
                  <img src={thinkOutside} className="thinkOutside" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://www.sirokygroup.com/" target="_blank">
                    <img src={tsg} className="tsg" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://www.vendomedia.co/" target="_blank">
                  <img src={vendoMedia} className="vendoMedia" />
                </a>
              </div>
              <div className="mobileOnly">
                <a href="https://www.vistarmedia.com/" target="_blank">
                  <img src={vistar} className="vistar" />
                </a>
              </div>
            </div>
            <div className="awardsLogoContainer logoWallTwo">
              <div className="desktopOnly">
                <a href="https://recmedia.com/" target="_blank">
                    <img src={recMedia} className="recMedia" />
                </a>
              </div>
              <div className="desktopOnly">
                <a href="https://statementsmedia.com/" target="_blank">
                    <img src={statements} className="statements" />
                </a>
              </div>
              <div className="desktopOnly">
                <a href="https://talonooh.com/can/" target="_blank">
                  <img src={thinkOutside} className="thinkOutside" />
                </a>
              </div>
              <div className="desktopOnly">
                <a href="https://www.sirokygroup.com/" target="_blank">
                    <img src={tsg} className="tsg" />
                </a>
              </div>
              <div className="desktopOnly">
                <a href="https://www.vendomedia.co/" target="_blank">
                  <img src={vendoMedia} className="vendoMedia" />
                </a>
              </div>
              <div className="desktopOnly">
                <a href="https://www.vistarmedia.com/" target="_blank">
                  <img src={vistar} className="vistar" />
                </a>
              </div>
              <div className="subAwardsImgTitle ingrid-sponsor" style={{display: 'none'}}>
                <span>LOGISTICS SPONSOR</span>
                <img src={lineSmall} width="330" className="smallLineDeco"/>
                <a href="https://mediastaffinginc.ca/" target="_blank">
                  <img src={mediaStaffing} className="mediaStaffing" />
                </a>
              </div>
              <div className="subAwardsImgTitle flexStartAwards ingrid-sponsor" style={{display: 'none'}}>
                <span>PHOTO / VIDEO SPONSOR</span>
                <img src={lineSmall} width="330" className="smallLineDeco"/>
                <a href="https://1mpressionmedia.com/" target="_blank">
                  <img src={impression} className="impression" />
                </a>
              </div>
            </div>
            <div className="sponsor-partners">
              <div className="subAwardsImgTitle">
                <span>LOGISTICS SPONSOR</span>
                <img src={lineSmall} width="330" className="smallLineDeco"/>
                <a href="https://mediastaffinginc.ca/" target="_blank">
                  <img src={mediaStaffing} className="mediaStaffing" />
                </a>
              </div>
              <div className="subAwardsImgTitle flexStartAwards">
                <span>PHOTO / VIDEO SPONSOR</span>
                <img src={lineSmall} width="330" className="smallLineDeco"/>
                <a href="https://1mpressionmedia.com/" target="_blank">
                  <img src={impression} className="impression" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="judges">
          <div className="container">
            <img src={judgesTitle} className="judgesImg thanksJudges" />
            <div className="awardsJudgesWrapper">
              <div>
                <a href="https://www.linkedin.com/in/ajaydurani/">
                  <img src={ajayD} />
                  <p>
                    <strong>Ajay Durani</strong><br />
                    DPAA
                  </p>
                  </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/andreapor">
                <img src={andreaP} />
                <p>
                  <strong>Andrea Por</strong><br />
                  Zulu Alpha Kilo
                </p>
                  </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/christinamroche">
                <img src={christinaR} />
                  <p>
                    <strong>Christina Roche </strong><br />
                    Zulu Alpha Kilo
                  </p>
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/janetxi/">
                <img src={janetXi} />
                  <p>
                    <strong>Janet Xi </strong><br />
                    Wealthsimple
                  </p>
                </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/kylafrancis">
                  <img src={kylaF} />
                  <p>
                    <strong>Kyla Francis </strong><br />
                    APEX Exchange
                  </p>
                </a>
              </div>
              <div>
                <a href="https://uk.linkedin.com/in/flyssy">
                  <img src={markF} />
                  <p>
                    <strong>Mark Flys</strong><br />
                    WOO
                  </p>
                </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/michelebeaulieu">
                  <img src={micheleB} />
                  <p>
                    <strong>Michele Beaulieu</strong><br />
                    Subway
                  </p>
                </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/nikkistone1">
                  <img src={nikkiS} />
                  <p>
                    <strong>Nikki Stone</strong><br />
                    GroupM
                  </p>
                </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/snlewis">
                  <img src={shannonL} />
                  <p>
                    <strong>Shannon Lewis</strong><br />
                    CMDC
                  </p>
                </a>
              </div>
              <div>
                <a href="https://ca.linkedin.com/in/raven-opatovsky">
                  <img src={ravenO} />
                  <p>
                    <strong>Raven Opatovsky</strong><br />
                    Canada Goose
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="aboutTheAwards">
          <div className="container">
            <h2>About The</h2>
            <img src={awardsTitle} className="awardsTitleImg" />
            <p>
              Launched in Spring 2023, the awards gala is an all-encompassing industry celebration recognizing  the creative, technical,
              and strategic achievements of agencies and  advertisers for their out-of-home campaigns.
            </p>
            <p>
              Winning categories are focused on innovation, data, use of programmatic/digital technology, audience targeting, 360 planning, and more.
              <br /><br />
            </p>
            <p>
              From outdoor to place-based media; from painted murals to 3D digital spectaculars and everything in between, The Canadian Out-of-Home Awards is celebrating success from every corner of our industry.
            </p>
            <p>
            The  out-of-home (OOH) industry in Canada is a rapidly growing channel in  the marketing and advertising sector.  OOH consists of media that  reaches people outside of their homes, such as billboards, posters,  digital signage, transit stop and vehicle ads, audio-visual displays,  experiential activations, and more.  A powerful way to reach large,  diverse audiences and create lasting impressions, OOH’s capabilities are  truly unmatched by any other medium.
            </p>
            <a href="https://www.youtube.com/@COMMB/videos" className="btnOutline">
              <span>Click Here for the Winners’ <br className="mobileOnly"/> Gallery of 2024 AWARDS</span>
            </a>
            <h2 className="lastTitle">For 2025 partnership inquiries, please contact <a href="mailto:awards@commb.ca">awards@commb.ca</a></h2>
          </div>
        </section>
        </div>
    </Layout>
  </div>
)

export default Awards